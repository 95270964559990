@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap');
@import url('https://use.typekit.net/wks8rrt.css');
@import './variable.scss';

html,
body {
  width: 100vw;
  height: 100%;
  border: 0;
  margin: 0;
  padding: 0;
  outline: 0;
  background-color: #fffef5;
  overflow-x: hidden;
}

* {
  font-family: 'Poppins', sans-serif;
  box-sizing: border-box;

  &::-moz-selection {
    color: rgb(94, 140, 255);
    background: rgb(212, 224, 255);
  }

  &::selection {
    color: rgb(94, 140, 255);
    background: rgb(212, 224, 255);
  }
}

@font-face {
  font-family: pytagoras;
  src: url('../resources/fonts/pythago0.ttf');
}

.flex {
  display: flex;
  flex-direction: row;
}

.flex-column {
  flex-direction: column;
}

.App {
  width: 100%;

  & > h1 {
    text-align: center;
  }

  &-comment {
    text-align: center;
  }
}

.main-content {
  margin-top: 64px;

  @media all and (max-width: 768px) {
    margin-top: 113px;
  }
}

.input-field {
  font-size: 14px;
  font-weight: bold;
  margin-top: 8px;

  & > i {
    color: $black-light;
  }

  & > div {
    & > input[type='text'],
    & > input[type='number'] {
      width: 60%;
      max-width: 320px;
      padding: 8px;
      outline: 0;
      border: 0;
      border-bottom: 1px solid transparent;
      transition: 0.3s;

      &:focus {
        border-bottom: 1px solid $blue;
      }
    }
  }

  & > input[type='text'],
  & > input[type='number'] {
    width: 60%;
    max-width: 320px;
    padding: 8px;
    outline: 0;
    border: 0;
    border-bottom: 1px solid transparent;
    transition: 0.3s;

    &:focus {
      border-bottom: 1px solid $blue;
    }
  }
}


.container {
  width: 100%;
  padding: 64px 15%;

  &-section {
    margin-top: 32px;
    font-size: 18px;
    font-weight: 600;

    & > p {
      font-weight: normal;
      font-size: 16px;
    }
  }
}

@media (max-width: 768px) {
  .container {
    padding: 32px 8px;
  }
}

// Custom Radio
/* The container */
.custom-radio {
  display: flex;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 16px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default radio button */
.custom-radio input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

/* Create a custom radio button */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: $white-blue;
  border-radius: 50%;
}

/* On mouse-over, add a grey background color */
.custom-radio:hover input ~ .checkmark {
  background-color: $blue-lighter;
}

/* When the radio button is checked, add a blue background */
.custom-radio input:checked ~ .checkmark {
  background-color: $blue;
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.checkmark:after {
  content: '';
  position: absolute;
  display: none;
}

/* Show the indicator (dot/circle) when checked */
.custom-radio input:checked ~ .checkmark:after {
  display: block;
}

/* Style the indicator (dot/circle) */
.custom-radio .checkmark:after {
  top: 9px;
  left: 9px;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: white;
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.router-style {
  display: flex;
  flex-direction: column;
  background: #fffef5;
  @media all and (min-width: 1350px) and (max-width: 1550px) {
    zoom: 0.8;
  }
}

.h2 {
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 48px;
  line-height: 140%;
  text-align: center;
  color: #000000;
  letter-spacing: unset;
  margin: 0;
  padding: 0;

  @media all and (max-width: 600px) {
    font-size: 22px;
  }
}

.h3 {
  font-weight: 500;
  font-size: 24px;
  line-height: 42px;
  color: black;
  margin: 0;
  padding: 0;
  text-align: start;
  margin-bottom: 20px;
  text-transform: unset;
  font-family: 'Poppins', sans-serif;

  @media all and (max-width: 400px) {
    margin-bottom: 0px;
    font-size: 21px;
  }

  span {
    color: #d0d0cd;
  }
}

.p {
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 170%;
  text-align: center;
  color: #5c5c5c;
  @media all and (max-width: 400px) {
    font-size: 16px;
  }
}

.h4 {
  font-weight: 400;
  font-size: 22px;
  line-height: 160%;
  color: #000000;

  margin: 0;
  padding: 0;
  text-align: flex-start;
  @media all and (max-width: 500px) {
    text-align: center;
    font-size: 19px;
  }
}

.blue-button {
  background: #5e8cff;
  border: none;
  border-radius: 14px;
  border-bottom: 10px solid #3364e0;
  outline: none;
  -webkit-user-select: none;
  user-select: none;
  padding: 9px 35px;
  color: #ffffff;
  font-weight: 500;
  font-size: 24px;
  font-family: 'Poppins', sans-serif;
  text-align: center;
  text-decoration: none;

  &:hover {
    color: white;
    opacity: 0.9;
  }

  &:active {
    background: #5583f8;
    border-bottom: 10px solid #2d5fdf;
  }

  &:link {
    text-decoration: none;
  }
}

.centered-loader {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.pt-1 {
  padding-top: 8px;
}

.pt-2 {
  padding-top: 16px;
}

.pt-3 {
  padding-top: 24px;
}

.pt-4 {
  padding-top: 32px;
}

.pt-5 {
  padding-top: 40px;
}