.pre-feature { 
  .partners {

    width: 100%;
    .partner {
      width: 177px;
      display: flex;
      margin: 0 auto;
    }
  } 
}

.feature {
  margin-top: 100px;
  margin-bottom: 50px;
  padding-top: 50px;
  padding-bottom: 50px;
  background-color: #F2ECEC;

  @media all and (max-width: 1000px) {
    padding-top: 50px;
    padding-bottom: 50px;
  }
  @media all and (max-width: 390px) {
    padding-top: 20px;
  padding-bottom: 20px;
}

  &__container {
    h1 {
      font-family: 'Poppins', sans-serif;
      font-style: normal;
      font-weight: 600;
      font-size: 68px;
      line-height: 140%;
      text-align: center;
      color: #000000;

      @media all and (max-width: 500px) {
        font-size: 38px;
        padding: 0;
        margin-top: 50px;
      }
    }
  }
  .partners {
    display: flex;
    margin: 0 auto;
    max-width: 1270px;
    justify-content: space-between;

    @media all and (max-width: 1360px) {
      flex-wrap: wrap;
      justify-content: space-between;
      flex-direction: column;
      align-items: center;

      .partner {
        margin-bottom: 30px;
        &:first-child {
          margin: 30px 0;
        }
      }
    }

    @media all and (max-width: 450px) {
      margin-top: 100px;
    }

    @media all and (max-width: 400px) {
      margin-top: 50px;
    }

    @media all and (max-width: 400px) {
      margin-top: 100px;
    }

    .partner {
      display: flex;
      align-items: center;
      justify-content: center;


    }

    > div > a > img {
      filter: grayscale(0);

      max-height: 81px;


      @media all and (max-width: 1360px) {
        margin: 30px 30px;
      }

      &:hover {
        filter: grayscale(100);
      }
    }
  }
}
