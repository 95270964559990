@import '../../styles/variable.scss';

/*
.header__wefounder {
  background-color: #FF9A23;
  width: 100%;
  padding: 19px;

  a {
    display: inline-block;
    background-color: #000;
    color: #fff;
    padding: 15px 35px 15px 35px;
    border-radius: 35px;
    font-size: 18px;
    text-transform: uppercase;
    font-weight: bold;

    &:hover {
      text-decoration: none;
      color: #fff;
    }
  }
}
*/


.header__app-download {
  position: fixed;
  top: 0;
  width: 100%;
  background-color: #fff;
  z-index: 999;
  height: auto;

  @media all and (min-width: 768px) {
    display: none;
  }

  .close-button {
    margin-top: 0;
    padding-bottom: 0;
    svg {
      font-size: 24px;
    }
  }

  .app-download-container {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin: 0 auto;
    margin-top: 7px;
    max-width: 768px;
    padding: 10px 20px;
    border-bottom: 1px solid #ccc;

    .app-logo {
      margin-right: 20px;
      margin-left: 20px;

      @media all and (max-width: 550px) {
        margin-right: 10px;
        margin-left: 10px;
      }

      img {
        height: 46px;
      }
    }

    .content {
      @media all and (max-width: 550px) {
        font-size: smaller;
      }
    }

    .download-button {
      align-self: flex-end;
      margin: 0 0 0 auto;
      display: inline-block;
      
      button {
        padding: 6px 20px;
        margin: auto 0;
        background-color: #7FBC74;
        font-weight: bold;
        color: #fff;
        border-radius: 35px;
        text-transform: uppercase;
        margin-bottom: 13px;

        /*
        @media all and (max-width: 458px) {
          margin-bottom:13px;
        }
        */
      }
    }
  }

}

.header {
  height: 79px;
  padding: 0 20px;
  padding-top: 16px;
  background: #fffef5;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 999;

  @media all and (max-width:768px) {
    margin-top: 81px;

    &.app-download-header-hidden {
      margin-top: 0;
    }
  }

  @media all and (max-width: 458px) {
    margin-top: 81px;
  }

  &__container {
    margin: 0 auto;
    width: 1320px;
    align-items: center;
    display: flex;
    justify-content: space-between;

    @media all and (max-width: $small) {
      width: 100%;
    }
  
    .logo {
      img {
        height: 48px;
      }
    }

    .links {
      display: flex;
      flex: 1;
      justify-content: center;

      @media all and (max-width: 768px) {
        display: none;
      }

      ul {
        display: flex;
        align-items: center;
        margin-bottom: 0;
        padding-left: 0;

        li {
          list-style: none;
          a {
            color: #5c5c5c;
            cursor: pointer;
            font-size: 18px;
            font-weight: 400;
            line-height: 27px;
            margin: 0 23px;

            &:hover {
              opacity: 0.9;
              text-decoration: none;
            }
          }
        }
      }
    }
    .app-download-image {
      display: flex;
      justify-content: flex-end;
      width: 405px;

      @media all and (max-width: $small) {
        display: none;
      }

      .apple-store {
        img {
          height: 56px;
        }
      }

      .google-play {
        margin-left: 13px;
        img {
          height: 56px;
        }
      }
    }

    .toggle-modal {
      @media all and (min-width: 1001px) {
        display: none;
      }

      svg {
        font-size: 24px;
      }
    }

    .download-buttons {
      position: relative;

      @media all and (max-width: 1001px) {
        display: none;
      }

      .app-store {
        img {
          height: 56px;
        }
        @media all and (max-width: 407px) {
          img {
            display: block;
            margin-left: auto;
            margin-right: auto;
          }
        }
      }
      
      .google-play {
        display: inline-block;
        
        img {
          margin-left: 20px;
          height: 56px;
        }
        
        @media all and (max-width: 629px) {
          width: 100%;

          img {
            margin-top: 20px;
            margin-left: auto;
          }
        }

        @media all and (max-width: 407px) {
          img {
            display: block;
            margin-left: auto;
            margin-right: auto;
          }
        }
      }

      .apple-featured {
        position: absolute;
        top: 0;
        right: 0;
        @media all and (max-width: 407px) {
          display: none;
        }
      }

    }
  }
  .mobile-modal-container {
    background: rgba(0, 0, 0, 0.6);
    opacity: 0;
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 9999999999;
    pointer-events: none;
    transition: 0.1s ease opacity;

    &.active {
      opacity: 1;
      pointer-events: all;
    }
  }
  .mobile-modal {
    background: #ecf2fc;
    border-radius: 24px;
    min-height: 300px;
    padding: 20px 0;
    position: absolute;
    right: 20px;
    top: 20px;

    width: calc(100% - 40px);
    z-index: 999999999999;
    box-shadow: 4px 4px 9px 0px #21252933;

    @media all and (min-width: 500px) {
      width: 300px;
    }

    ul {
      align-items: center;
      display: flex;
      flex-direction: column;
      height: calc(100% - 50px);
      justify-content: center;
      margin: 0;
      padding-left: 0;
      padding-top: 25px;

      li {          height: 56px;
        list-style: none;
        margin: 0;
        margin-bottom: 20px;

        a {
          color: #5c5c5c;
          cursor: pointer;
          font-size: 22px;
          font-weight: 500;
          line-height: 27px;
          margin: 0 23px;
          text-align: center;

          &:hover {
            opacity: 0.9;
            text-decoration: none;
          }
        }
      }
    }

    .close-icon {
      font-size: 24px;
      position: absolute;
      right: 20px;
      top: 10px;
      z-index: 22;
    }
  }

  .mobile-appstore {
    display: flex;
    justify-content: center;

    img {
      height: 36px;
    }
  }

  .mobile-googleplay {
    margin-top: 40px;
    display: flex;
    justify-content: center;

    img {
      height: 36px;
    }
  }
}

.active-route a {
  color: black !important;
}
