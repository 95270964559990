.novelty {
  max-width: 1320px;
  margin: 0 auto;

  padding: 160px 20px;
  @media all and (max-width: 600px) {
    margin-top: 30px;
    padding: 40px 20px;
  }

  &__container {
    h2 {
      margin-bottom: 100px;

      @media all and (max-width: 650px) {
        font-size: 38px;
        margin-bottom: 20px;
      }
    }
    .content {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;

      @media all and (max-width: 950px) {
        flex-direction: column;
        justify-content: center;
        align-items: center;
      }
      .element {
        width: 620px;
        min-height: 590px;
        margin-bottom: 80px;

        @media all and (max-width: 1350px) {
          width: 48%;
        }

        @media all and (max-width: 950px) {
          width: 620px;
        }

        @media all and (max-width: 650px) {
          width: 100%;
          margin-bottom: 40px;
        }

        h4 {
          font-family: 'Poppins' sans-serif;
          font-style: normal;
          font-weight: 500;
          font-size: 28px;
          line-height: 42px;
          color: black;
          margin: 0;
          padding: 0;
          margin-bottom: 20px;
          span {
            color: #d0d0cd;
          }

          @media all and (max-width: 650px) {
            font-size: 21px;
          }
        }

        p {
          text-align: start;

          span {
            color: black;
            display: inline-block;
          }
        }

        img {
          width: 100%;
          margin-bottom: 20px;
        }
      }
    }
  }
}
