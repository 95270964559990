@import '../../../styles/variable.scss';

.main-banner {
  max-width: 1340px;
  margin: 0 auto;
  padding: 0 20px;
  padding-top: 63px;
  min-height: 790px;

  @media all and (max-width: 1500px) {
    max-width: 1250px;
  }

  @media all and (max-width: 630px) {
    padding: 0 20px;
    padding-top: 20px;
  }

  @media all and (max-width: 400px) {
    padding: 0px 10px;
    padding-top: 20px;
  }

  &__container {
    display: flex;
    justify-content: 'flex-end';
    height: 100%;

    @media all and (max-width: 1000px) {
      flex-direction: column;
    }

    .left-part {
      width: 50%;
      padding-top: 59px;

      @media all and (max-width: 1000px) {
        width: 100%;
        padding-top: 55px;
      }

      .text-container {

        @media all and (max-width: 1000px) {
          max-width: 583px;
          margin: 0 auto;
        }

        h1 {
          font-family: 'Poppins', sans-serif;
          font-style: normal;
          font-weight: 600;
          font-size: 65px;
          line-height: 140%;
          max-width: 608px;
          padding: 0;
          margin-bottom: 21px;
          color: #000000;

          @media all and (max-width: 1250px) {
            font-size: 55px;
          }
          @media all and (max-width: 1150px) {
            font-size: 50px;
          }
          @media all and (max-width: 1000px) {
            font-size: 50px;
          }
          @media all and (max-width: 550px) {
            font-size: 40px;
            max-width: 100%;
          }
        }

        p {
          font-family: 'Poppins', sans-serif;
          font-style: normal;
          font-weight: 500;
          font-size: 24px;
          line-height: 170%;
          color: #7e7c64;
          max-width: 399px;
          margin-bottom: 30px;

          @media all and (max-width: 550px) {
            font-size: 20px;
          }
        }
      }
    }

    .right-part {
      padding-top: 85px;
      width: 50%;
      display: flex;
      justify-content: flex-end;
      position: relative;
      transform: scale(0.9);

      @media all and (max-width: 1000px) {
        width: 100%;
      }

      .phone-background {
        @media all and (max-width: 1000px) {
          width: 470px;
          height: 800px;
          margin: 0 auto;
          position: relative;
          padding-top: 80px;
        }
        @media all and (max-width: 550px) {
          height: 645px;
          width: 100%;
        }
        .background {
          width: 510px;
          height: 652px;
          margin-top: 140px;
          position: absolute;
          right: 0;
          z-index: 10;

          @media all and (max-width: 1250px) {
            width: 490px;
            height: 632px;
          }
          @media all and (max-width: 1150px) {
            width: 470px;
            height: 612px;
          }
          @media all and (max-width: 550px) {
            width: 100%;
            height: unset;
          }
        }

        .phone {
          width: 352px;
          height: 722px;
          z-index: 120;
          position: absolute;
          right: 70px;

          @media all and (max-width: 1250px) {
            width: 332px;
            height: 702px;
          }
          @media all and (max-width: 1150px) {
            width: 312px;
            height: 682px;
          }
          @media all and (max-width: 550px) {
            width: 80%;
            height: unset;
            left: 10%;
          }
        }
      }
    }
  }
}

.styled-text {
  border-radius: 52px;
  padding: 12px 32px;
  display: inline-block;
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 44px;
  line-height: 66px;
  margin: 0 10px;

  @media all and (max-width: 550px) {
    font-size: 23px;
    padding: 15px 10px;
    line-height: 20px;
  }
}

.chat-panda-image {
  width: 375px;
  height: 375px;
}

.chat-button {
  margin-top: 32px;
  width: 100%;
  
  .styled-text {
    font-size: 24px;
    padding: 15px 35px;
  }
}