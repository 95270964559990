.try-earkick {
  max-width: 1360px;
  margin: 0 auto;
  margin-top: 300px;
  padding: 0 20px;

  @media all and (max-width: 600px) {
    margin-top: 230px;
  }

  &__container {
    width: 100%;
    background: #fff7ac;
    border-radius: 32px;
    display: flex;
    align-items: center;
    flex-direction: column;
    padding-bottom: 72px;

    @media all and (max-width: 600px) {
      padding: 10px;
      margin-top: 130px;
      padding-bottom: 25px;
    }

    .circle-earkick {
      width: 200px;
      height: 200px;
      background: #ffe79b;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 100%;
      margin-top: -100px;
      margin-bottom: 48px;

      img {
        width: 60%;
      }

      @media all and (max-width: 600px) {
        width: 180px;
        height: 180px;
        margin-top: -100px;
        margin-bottom: 20px;
      }
    }

    .earkick-bear {
      @media all and (max-width: 600px) {
        width: 50%;
      }
    }

    h2 {
      font-weight: 600;
      font-size: 48px;
      line-height: 140%;
      text-align: center;
      color: #000000;
      margin: 0;
      padding: 0;
      margin-bottom: 20px;
      @media all and (max-width: 600px) {
        font-size: 30px;
      }
    }

    p {
      font-weight: 500;
      font-size: 18px;
      color: #7e7c64;
      line-height: 170%;
      text-align: center;
      margin: 0;
      padding: 0;
      margin-bottom: 30px;

      @media all and (max-width: 600px) {
        font-size: 16px;
      }
    }

    .download-apps {

      a {
        padding: 0;
        img {
          margin: 12px;
          width: auto;
        }
      }

      .app-store {
        img {
          height: 56px;
        }
      }

      .google-play {
        display: inline-block;

        img {
          margin-left: 20px;
          height: 56px;
        }
      }
      
      @media all and (max-width: 600px) {
        font-size: 16px;
        a {
          display: block;
        }

        img {
          margin-right: 0;
        }

        .google-play {
          img {
            margin: 0 12px;
            height: 56px;
          }
        }
      }
    }
  }

  .powered-by-eleven-labs {
    display: flex;
    margin-top: 25px;
    gap: 10px;

    @media all and (max-width: 550px) {
      width: 350px;
      display: block;
    }

    .pre-text {
      font-weight: 500;
      font-size: 18px;
      color: #7e7c64;
      line-height: 170%;
      display: inline-block;
      line-height: 40px;
      
      @media all and (max-width: 550px) {
        width: 100%;
        text-align: center;
      }
    }

    img {
      display: inline-block;
      width: 350px;
            
      @media all and (max-width: 550px) {
        width: 220px;
        margin-left: 65px;
      }
    }
  }
}
