.advisors {
  max-width: 1320px;
  margin: 0 auto;
  padding-bottom: 160px;

  @media all and (max-width: 1350px) {
    padding: 0 40px;
    padding-bottom: 160px;
  }

  @media all and (max-width: 600px) {
    padding-bottom: 60px;
  }

  &__container {
    .content {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-wrap: wrap;

      .element {
        width: 250px;
        margin: 20px;

        img {
          width: 250px;
        }
      }
    }
  }
}

.advisor-background {
  background: #f3f2e9;
  padding-top: 160px;

  @media all and (max-width: 600px) {
    padding-top: 60px;
  }
}
