@import "../../styles/variable.scss";

.privacy-policy {
  width: 100%;
  padding: 64px 15%;

  &-section {
    margin-top: 32px;
    font-size: 18px;

    & > p {
      font-weight: normal;
      font-size: 16px;
    }
  }

  .term {
    text-decoration: underline;
  }
  
  ol.c0 {
    margin-top: 40px;
    padding-left: 25px;

    ::marker {
      font-size: 17px;
      font-weight: 700;
      color: #354170;
    }

    h3 {
      margin-left: 20px;
    }
  }

  h4 {
    font-size: 17px;
  }
}

h1 {
  font-size: 37px;
  font-weight: 700;
  color: #354170;
  z-index: 3;
  letter-spacing: 2px;
  padding-bottom: 60px;
}

h3 {
  margin-top: 30px;
  font-size: 17px;
  font-weight: 700;
  color: #354170;
  z-index: 3;
  padding-bottom: 20px;
  text-transform: uppercase;
}

a {
  color: #45B9B2;
}
@media (max-width: 768px) {
  .privacy-policy {
    padding: 32px 8px;
  }
}
