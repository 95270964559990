.user {
  max-width: 1360px;
  margin: 0 auto;
  margin-top: 200px;
  padding: 0 20px;

  @media all and (max-width: 600px) {
    margin-top: 80px;
  }

  &__container {
    h2 {
      margin-bottom: 100px;
    }

    .content {
      width: 100%;
      height: 100%;
      display: flex;
      flex-wrap: wrap;
      align-items: self-start;

      @media all and (max-width: 1360px) {
        flex-direction: column;
        justify-content: center;
        align-items: center;
      }

      .first-element {
        background: #f3f2e9;
        border-radius: 32px;
        max-width: 620px;
        padding: 50px 60px;
        margin: 20px;

        @media all and (max-width: 600px) {
          padding: 20px 20px;
          margin: 0;
          margin-bottom: 20px;
          width: 100%;
        }

        h3 {
          font-weight: 500;
          font-size: 22px;
          line-height: 42px;
          color: #000000;
          margin: 0;
          padding: 0;
          margin-bottom: 12px;
          letter-spacing: 0px;
          margin-top: 12px;
          text-transform: unset;
          text-align: start;
          @media all and (max-width: 600px) {
            text-align: center;
            font-size: 24px;
          }
        }
        p {
          font-weight: 400;
          font-size: 16px;
          line-height: 170%;
          margin: 0;
          padding: 0;
          color: #5c5c5c;
          @media all and (max-width: 600px) {
            font-size: 16px;
            text-align: center;
          }
        }

        .header-style {
          display: flex;
          justify-content: flex-start;
          align-items: center;

          @media all and (max-width: 600px) {
            flex-direction: column;
            justify-content: center;
          }

          .circle {
            margin-right: 25px;
            @media all and (max-width: 600px) {
              margin-right: 0;
            }
          }
          .stars-style {
            display: flex;
            flex: 1;
            justify-content: flex-end;
          }
        }
      }
      .margin-style {
        margin-top: -118px;

        @media all and (max-width: 1360px) {
          margin-top: 20px;
        }

        @media all and (max-width: 600px) {
          margin-top: 0px;
        }
      }
    }
  }
}

.styled-text {
  border-radius: 52px;
  padding: 8px 29px;
  display: inline-block;
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 40px;
  line-height: 66px;
  margin: 0 10px;

  @media all and (max-width: 550px) {
    font-size: 23px;
    padding: 15px 10px;
    line-height: 20px;
  }
}
