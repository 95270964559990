@import "../../styles/variable.scss";

.terms-of-service {
  width: 100%;
  padding: 64px 15%;

  &-section {
    margin-top: 32px;
    font-size: 18px;
    font-weight: 600;

    & > p {
      font-weight: normal;
      font-size: 16px;
    }
  }

  .term {
    text-decoration: underline;
  }

  ol.c0 {
    margin-top: 40px;
    padding-left: 25px;

    ::marker {
      font-size: 17px;
      font-weight: 700;
      color: #354170;
    }

    h3 {
      margin-left: 20px;
    }
  }
}

@media (max-width: 768px) {
  .terms-of-service {
    padding: 32px 8px;
  }
}
