.resources {
  max-width: 1320px;
  margin: 0 auto;
  padding: 0 20px;

  &__container {
    h2 {
      margin-bottom: 100px;
    }

    p {
      font-weight: 400;
      font-size: 20px;
      line-height: 170%;
      color: #5c5c5c;
      span {
        color: #000000;
      }
    }
  }
}
