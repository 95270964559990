.health-predictions {
  max-width: 1320px;
  margin: 0 auto;
  margin-top: 200px;
  padding: 160px 20px;
  @media all and (max-width: 600px) {
    margin-top: 30px;
    padding: 40px 20px;
  }

  &__container {
    h2 {
      font-family: 'Poppins', sans-serif;
      font-style: normal;
      font-weight: 600;
      font-size: 40px;
      margin-bottom: 200px;
      line-height: 130%;
      text-align: center;
      color: #000000;
      max-width: 840px;
      margin: 0 auto;
      margin-bottom: 72px;
      text-transform: unset;

      @media all and (max-width: 600px) {
        font-size: 30px;
      }
    }
    .content {
      display: flex;
      justify-content: center;
      align-items: flex-start;

      @media all and (max-width: 1350px) {
        flex-direction: column;
        align-items: center;
      }

      .part {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
      }

      .element {
        width: 620px;
        min-height: 156px;
        background: #fffef5;
        border-radius: 32px;
        margin: 20px;
        padding: 28px;
        display: flex;
        align-items: center;
        justify-content: center;

        @media all and (max-width: 650px) {
          height: unset;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          width: 100%;
          margin: 10px 0;
        }

        img {
          margin-right: 30px;
          align-self: flex-start;

          @media all and (max-width: 650px) {
            width: 100px;
            margin-bottom: 20px;
            margin-right: 0;
            align-self: unset;
          }
        }

        h2 {
          flex: 1;
          font-weight: 400;
          font-size: 20px;
          line-height: 170%;
          color: #000000;
          margin: 0;
          padding: 0;
          letter-spacing: unset;
          text-align: start;
          @media all and (max-width: 500px) {
            text-align: center;
            font-size: 19px;
          }
        }
      }
    }
  }
}

.health-predictions-background {
  background-color: #f3f2e9;
}
