@import '../../../styles/variable.scss';

.video {
  margin-top: 200px;

  @media all and (max-width: 1350px) {
    padding: 0 20px;
  }
  @media all and (max-width: 500px) {
    margin-top: 30px;
  }
  &__container {
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    border-radius: 40px;
    overflow: hidden;

    iframe {
      border: none;
    }

    @media all and (max-width: 1500px) {
      max-width: 1200px;
      height: 555px;
    }

    @media all and (max-width: 900px) {
      height: 500px;
    }

    @media all and (max-width: 500px) {
      height: 250px;
    }
  }
}
