.main-feature-list {
  max-width: 1320px;
  margin: 0 auto;
  margin-top: 200px;
  padding: 0 20px;

  @media all and (max-width: 600px) {
    margin-top: 30px;
  }

  &__container {
    h2 {
      margin-bottom: 100px;
    }
  }
  .content {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;

    .element {
      width: 600px;
      height: 156px;
      background: #f3f2e9;
      border-radius: 32px;
      margin: 20px;
      padding: 28px;
      display: flex;
      align-items: center;
      justify-content: flex-start;

      @media all and (max-width: 600px) {
        height: unset;
        flex-direction: column;
        align-items: center;
      }

      img {
        margin-right: 30px;

        @media all and (max-width: 500px) {
          width: 100px;
          margin-bottom: 20px;
          margin-right: 0;
        }
      }
    }
  }
  .privacy-content {
    width: 100%;
    height: 100%;
    margin-top: 200px;

    @media all and (max-width: 600px) {
      margin-top: 0;
    }

    &__container {
      width: 100%;
      height: 100%;
      display: flex;
      justify-items: space-between;
      @media all and (max-width: 1300px) {
        padding: 0 20px;
      }

      @media all and (max-width: 1100px) {
        flex-direction: column;
        justify-content: center;
      }

      &-inner {
        width: 50%;

        &.first {
          transform: scale(0.9);
        }

        @media all and (max-width: 1100px) {
          width: 100%;
          display: flex;
          align-items: center;
          flex-direction: column;
          justify-content: center;
        }

        @media all and (max-width: 600px) {
          order: 2;
        }

        img {
          width: 620px;

          @media all and (max-width: 1300px) {
            width: 520px;
          }

          @media all and (max-width: 1100px) {
            max-width: 620px;
            width: 100%;
          }
        }

        h2 {
          margin-bottom: 30px;
          margin-top: 100px;
        }

        p {
          font-weight: 400;
          font-size: 20px;
          line-height: 170%;
          color: #5c5c5c;
        }
        &.second {
          h2 {
            margin-bottom: 20px;
          }
          margin-left: 150px;

          @media all and (max-width: 1300px) {
            margin-left: 50px;
          }

          @media all and (max-width: 1100px) {
            max-width: 70%;
            margin: 0 auto;
          }

          @media all and (max-width: 600px) {
            max-width: 100%;
            margin: 0 auto;
            order: 1;
            align-items: self-start;
          }
        }
      }
    }
  }
}

.styled-text {
  border-radius: 52px;
  padding: 8px 29px;
  display: inline-block;
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 40px;
  line-height: 66px;
  margin: 0 10px;

  @media all and (max-width: 550px) {
    font-size: 23px;
    padding: 15px 10px;
    line-height: 20px;
  }
}
