@import '../../../styles/variable';

.our-story {
  max-width: 1280px;
  margin: 0 auto;
  margin-top: 150px;
  margin-bottom: 280px;

  @media all and (max-width: 1350px) {
    padding: 0 40px;
  }

  @media all and (max-width: 600px) {
    margin-top: 20px;
    margin-bottom: 100px;
    padding: 0 20px;
  }

  &__container {
    display: flex;

    @media all and (max-width: 1150px) {
      flex-direction: column;
    }

    .blue-button {
      text-decoration: none;
    }

    .left-part {
      width: 50%;

      @media all and (max-width: 1150px) {
        width: 80%;
        margin: 0 auto;
      }
      @media all and (max-width: 600px) {
        width: 100%;
        order: 2;
      }

      .story-text {
        max-width: 495px;

        @media all and (max-width: 1150px) {
          max-width: 100%;
        }

        h1 {
          font-weight: 600;
          font-size: 68px;
          line-height: 140%;
          color: #000000;
          margin: 0;
          padding: 0;
          margin-bottom: 21px;
          letter-spacing: unset;

          @media all and (max-width: 600px) {
            display: none;
            font-size: 38px;
          }
        }
        p {
          margin: 0;
          padding: 0;
          font-weight: 500;
          font-size: 22px;
          line-height: 170%;
          color: #7e7c64;
          margin-bottom: 10px;

          @media all and (max-width: 600px) {
            margin-top: 30px;
            font-size: 20px;
          }

          span {
            color: #000000;
            display: inline-block;
          }
        }
        img {
          margin-top: 31px;
        }
      }
    }

    .right-part {
      width: 50%;

      @media all and (max-width: 1150px) {
        width: 100%;
        margin: 0 auto;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 20px;
        order: 1;
      }

      @media all and (max-width: 600px) {
        display: flex;
        flex-direction: column;

        h1 {
          font-weight: 600;
          font-size: 68px;
          line-height: 140%;
          color: #000000;
          margin: 0;
          padding: 0;
          margin-bottom: 21px;
          letter-spacing: unset;
          font-size: 38px;
        }
      }

      @media all and (min-width: 600px) {
        h1 {
          display: none;
        }
      }

      img {
        width: 600px;
        user-select: none;

        @media all and (max-width: 1350px) {
          width: 100%;
        }

        @media all and (max-width: 1100px) {
          width: 50%;
        }

        @media all and (max-width: 600px) {
          margin-top: 50px;
          width: 100%;
        }
      }
    }
  }
}
