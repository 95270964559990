.footer {
  max-width: 1360px;
  margin: 0 auto;
  margin-top: 200px;
  padding: 0 20px;
  width: 100%;
  text-align: center;
  margin-bottom: 200px;

  @media all and (max-width: 600px) {
    margin-top: 30px;
    margin-bottom: 30px;
  }

  .footer__container {
    .privacy-style {
      font-weight: 400;
      font-size: 20px;
      line-height: 170%;
      text-align: center;
      color: #5c5c5c;
      text-transform: capitalize;

      @media all and (max-width: 600px) {
        font-size: 16px;
      }
    }

    .socials {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 40px;

      @media all and (max-width: 600px) {
        margin-bottom: 20px;
      }

      .element {
        width: 80px;
        height: 80px;
        display: flex;
        align-items: center;
        justify-content: center;
        background: #f3f2e9;
        border-radius: 100%;
        margin: 10px;

        img {
          width: 24px;
          height: 24px;
        }

        @media all and (max-width: 600px) {
          width: 40px;
          height: 40px;

          img {
            width: 20px;
            height: 20px;
          }
        }
      }
    }
  }
}
