.mental-health {
  max-width: 1320px;
  margin: 0 auto;
  padding: 0 20px;
  padding-top: 183px;

  @media all and (max-width: 1440px) {
    padding-top: 83px;
  }

  &__container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 200px;

    @media all and (max-width: 1210px) {
      flex-direction: column;
    }

    .left {
      max-width: 620px;

      @media all and (max-width: 1210px) {
        margin-bottom: 30px;
      }

      h1 {
        margin: 0;
        padding: 0;
        font-family: 'Poppins', sans-serif;
        font-style: normal;
        font-weight: 600;
        font-size: 68px;
        line-height: 140%;
        color: #000000;
        margin-bottom: 22px;

        @media all and (max-width: 600px) {
          font-size: 38px;
        }
      }

      h5 {
        margin: 0;
        padding: 0;
        font-family: 'Poppins', sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 22px;
        margin-bottom: 15px;

        line-height: 170%;
        color: #7e7c64;
        max-width: 510px;

        @media all and (max-width: 600px) {
          font-size: 20px;
        }
      }

      .button-wrapper {
        margin-top: 58px;
        a {
          text-decoration: none;
        }
      }
    }

    .right {
      .phone-background {
        position: relative;
        overflow: hidden;
      }

      .background {
        @media all and (max-width: 600px) {
          width: 100%;
        }
      }
      .phone {
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        width: 375px;
        left: calc(20%);

        @media all and (max-width: 600px) {
          width: 60%;
        }
      }
    }
  }
}
