.info {
  max-width: 1280px;
  margin: 0 auto;
  margin-top: 200px;

  @media all and (max-width: 600px) {
    margin-top: 100px;
  }

  &__container {
    display: flex;
    justify-content: space-between;
    align-items: center;

    @media all and (max-width: 900px) {
      flex-direction: column;
    }

    .element {
      width: 400px;
      height: 322px;
      display: flex;
      flex-direction: column;
      align-items: center;

      @media all and (max-width: 900px) {
        margin-bottom: 50px;
      }

      .panda {
        width: 156px;
        height: 156px;
        display: flex;
        align-items: center;
        border-radius: 100%;
        justify-content: center;
        margin-bottom: 20px;

        &.yellow {
          background-color: #fff7ac;
        }
        &.green {
          background-color: #d4ffdb;
        }
        &.blue {
          background-color: #d4e0ff;
        }
      }
      .content {
        h3 {
          text-align: center;
          font-size: 28px;
        }
      }
    }
  }
}
