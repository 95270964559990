@import "../../styles/variable.scss";

.faq {
  width: 100%;
  padding: 100px 15% 0 15%;
  
  .faq-item {
    cursor: pointer;
    border-bottom: 1px solid #D9D9D9;
    position: relative;

    p {
      visibility: hidden;
      opacity: 0;
      transition: visibility 0s, opacity 0.5s linear, height 0.5s linear;
      height: 0;
      margin: 0;
    }

    &.open {
      p {
        visibility: visible;
        opacity: 1;
        height: auto;
        padding-bottom: 24px;
      }
    }

    .indicator {
      position: absolute;
      top: 24px;
      right: 8px;
      transform: rotate(225deg);
      transition: transform 0.25s linear;
      border-left: 2px solid #5F6368;
      border-top: 2px solid #5F6368;
      width: 10px;
      height: 10px;
    }

    &.open {
      .indicator {
        transform: rotate(45deg);
      }
    }
  }
}

@media (max-width: 768px) {
  .faq {
    padding: 32px 8px;
  }
}
