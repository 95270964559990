.investors {
  max-width: 1320px;
  margin: 200px auto;

  @media all and (max-width: 1350px) {
    padding: 0 40px;
  }
  @media all and (max-width: 600px) {
    margin: 40px auto;
  }

  &__container {
    h1 {
      font-weight: 600;
      font-size: 68px;
      line-height: 140%;
      text-align: center;
      color: #000000;
      margin: 0;
      padding: 0;
      margin-bottom: 22px;

      @media all and (max-width: 600px) {
        font-size: 38px;
      }
    }
    p {
      font-weight: 500;
      font-size: 22px;
      line-height: 170%;
      text-align: center;
      color: #7e7c64;
      margin: 0;

      @media all and (max-width: 600px) {
        font-size: 18px;
      }
    }
    .content {
      display: flex;
      justify-content: space-between;
      margin-top: 72px;

      @media all and (max-width: 1350px) {
        flex-direction: column;
      }

      img {
        @media all and (max-width: 1350px) {
          max-width: 600px;
          margin: 20px auto;
        }

        @media all and (max-width: 600px) {
          max-width: 100%;
          margin: 20px auto;
        }
      }
    }
  }
}
