.offices {
  max-width: 1320px;
  margin: 200px auto;

  @media all and (max-width: 1350px) {
    padding: 0 40px;
  }
  @media all and (max-width: 600px) {
    margin: 40px auto;
  }

  &__container {
    display: flex;
    justify-content: space-between;
    align-items: center;

    @media all and (max-width: 1200px) {
      flex-direction: column;
      justify-content: center;
    }

    .left-style {
      display: flex;

      @media all and (max-width: 1200px) {
        order: 2;
      }

      @media all and (max-width: 800px) {
        flex-direction: column;
      }
      img {
        margin: 0 20px;
        @media all and (max-width: 800px) {
          margin: 0;
          margin-bottom: 20px;
          width: 100%;
        }
      }
    }
    .right-style {
      width: 500px;
      @media all and (max-width: 1200px) {
        width: unset;
        order: 1;
        margin-bottom: 100px;
      }

      p {
        font-size: 24px;
        margin: 0;
        text-align: start;
      }

      h2 {
        text-align: start;
        @media all and (max-width: 1200px) {
          text-align: center;
        }
      }
    }
  }
}
