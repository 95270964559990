.work {
  max-width: 1280px;
  margin: 0 auto;
  margin-top: 200px;

  @media all and (max-width: 600px) {
    margin-top: 40px;
  }

  &__container {
    h2 {
      margin-bottom: 100px;
    }
    .content {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;

      @media all and (max-width: 1350px) {
        flex-wrap: wrap;
        justify-content: center;
      }

      .element {
        width: 400px;

        @media all and (max-width: 1350px) {
          margin: 20px;
        }

        .image-container {
          width: 400px;
          height: 400px;
          margin-bottom: 32px;
          position: relative;
          overflow: hidden;

          @media all and (max-width: 400px) {
            width: 100%;
            padding: 20px;
            height: 360px;
          }

          .background {
            position: absolute;

            @media all and (max-width: 400px) {
              left: 0;
              right: 0;
              bottom: 0;
              width: 100%;
            }
          }

          .phone {
            position: absolute;
            width: 280px;
            bottom: 0;
            left: calc(50% - 140px);

            @media all and (max-width: 400px) {
              zoom: 0.9;
            }
          }
        }
      }
    }
  }

  .styled-text {
    border-radius: 52px;
    padding: 8px 29px;
    display: inline-block;
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 40px;
    line-height: 66px;
    margin: 0 10px;

    @media all and (max-width: 550px) {
      font-size: 23px;
      padding: 15px 10px;
      line-height: 20px;
    }
  }
}

.phone-container {
  position: absolute;
  top: 51.37px;
  left: 13.51px;
  height: 288px;
  width: calc(100% - 2 * 13.5px);
  padding: 0px 13.52px;
  overflow: scroll;

  &::-webkit-scrollbar {
    display: none;
  }

  -ms-overflow-style: none;
  scrollbar-width: none;
}
.phone-header {
  display: flex;

  .phone-location {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;

    .phone-location-container {
      background: #f3f2e9;
      border-radius: 15px;
      margin-left: -27px;
      padding: 6px 11px;
      max-height: 25.17px;
      p {
        margin: 0;
        font-weight: 400;
        font-size: 8.78789px;
        line-height: 150%;
        /* or 13px */

        /* titles */

        color: #000000;
        padding: 0;
      }
    }
  }
}
.phone-stats {
  position: relative;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 30px;
}
.phone-stats-circle {
  width: 119.65px;
  height: 59.83px;

  border-top-left-radius: 59.83px;
  border-top-right-radius: 59.83px;
  border: 2.70397px solid rgba(216, 71, 228, 0.2);
  border-bottom: 0;

  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding-top: 25px;
  h3 {
    margin: 0px;
    // margin-top: 23.66px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 21.6317px;

    padding: 0px;
  }
  span {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 8.78789px;
    margin-top: 1px;
    text-align: center;

    /* body */

    color: #5c5c5c;
  }
}

.phone-stats-circle-inner {
  top: 29.5px;
  left: 51.5px;
  width: 119.65px;
  height: 62.33px;
  position: absolute;

  border-top-left-radius: 59.83px;
  border-top-right-radius: 59.83px;
  border: 5.40793px solid #f074fb;
  border-bottom: 0;

  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  transition: all ease 0.5s;
  transform-origin: 50% 95%;
}

.phone-second-stats {
  margin-top: 14px;
  background: rgba(216, 71, 228, 0.1);
  border-radius: 6.75991px;
  padding: 10.82px;
  width: 100%;
  padding-bottom: 16px;
  .container {
    display: flex;

    display: flex;
    justify-content: space-between;
    padding: 0px;
    
    > div {
      font-family: 'Poppins';
      font-style: normal;
      font-weight: 500;
      font-size: 8.78789px;
      line-height: 130%;
      margin: 0px;
      padding: 0px;
      margin-bottom: 5px;
    }
  }
  .pink-outer-line {
    height: 0px;
    height: 2.70397px;
    width: 100%;
    border-radius: 20px;
    background: rgba(216, 71, 228, 0.2);
    transition: all ease 0.5s;
    position: absolute;
    top: 1.2px;
    z-index: 0;
  }
  .pink-line {
    background: #f074fb;
    border-radius: 20px;
    height: 4.40793px;
    transition: all ease-in 0.1s;
    position: absolute;
    top: 0px;
    left: 0px;
    z-index: 1231231231;
  }
}

.phone-third-stats {
  width: 226.46px;
  height: 197.39px;
  margin-top: 10.82px;
}

.phone-container {
  padding-bottom: 30px;
  .meditation {
    h5 {
      font-family: 'Poppins';
      font-style: normal;
      font-weight: 400;
      font-size: 13.4933px;
      line-height: 128%;
      margin: 0px;
      padding: 0px;
      margin-bottom: 12px;
    }
  }
  .slider {
    display: flex;
    overflow-x: scroll;
    -ms-overflow-style: none;
    scrollbar-width: 0;
    img {
      width: 106.6px;
      height: 106.6px;
      object-fit: contain;
      margin-right: 10.79px;
    }
  }

  .slider::-webkit-scrollbar {
    display: none;
  }
}
