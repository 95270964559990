.about-socials {
  display: flex;
  justify-content: center;
  margin-top: 24px;

  a {
    margin: 6.5px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    transition: all 0.3s ease;

    img {
      width: 24px;
      height: 24px;
    }
  }

  &.herbert {
    width: 300px;
  }

  &.karin {
    width: 300px;
    margin-left: 300px;
    margin-top: -250px;
  }
}

.element-social {
  img {
    width: 24px;
    height: 24px;
  }
}